import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice.js";
import globalReducer from "./slices/globalSlice.js";

const store = configureStore({
    reducer: {
        auth: authReducer,     // Auth slice
        global: globalReducer  // Global slice
    }
});

export default store;
