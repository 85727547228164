// import { useEffect, useRef} from "react";
// import {
//   calculateBounds,
//   calculateCenter,
//   createCircle,
//   extractLatLong,
// } from "../../../utils/mapUtils.js";
// import { useSelector, useDispatch } from "react-redux";
// import "mapbox-gl/dist/mapbox-gl.css";
// import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
// import {
//   setBomaReviewDetails,
//   setIsReviewing,
//   setIsSidebarOpen,
//   setShowBomaCluster,
//   setBomaPopupDetails,
//   setActivePopup,
// } from "../../../store/slices/globalSlice.js";
// import { createPopup } from "../../../utils/createPopup.js";


// const MapLayer = ({ mapRef }) => {
//   const showBomaCluster = useSelector((state) => state.global.showBomaCluster);
//   const bomaClusterDetails = useSelector(
//     (state) => state.global.bomaClusterDetails
//   );
//   const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
//   const highlightedBomaDetails = useSelector(
//     (state) => state.global.highlightedBomaDetails 
//   );

//   const bomaPopupDetails = useSelector((state) => state.global.bomaPopupDetails);

//   const dispatch = useDispatch();
//   const popupRef = useRef(null); // Ref to keep track of the current popup


//   const activePopup = useSelector((state) => state.global.activePopup)

//   useEffect(() => {
//     if (showBomaCluster && mapRef.current && bomaClusterDetails) {
//       const circleCoordinates = extractLatLong(bomaClusterDetails);

//       if (circleCoordinates && circleCoordinates.length > 0) {
//         const center = calculateCenter(circleCoordinates);
//         const circleCoords = createCircle(center, 2.5);

//         if (mapRef.current.getLayer("circle-layer")) return;

//         mapRef.current.addSource("circle", {
//           type: "geojson",
//           data: {
//             type: "Feature",
//             geometry: { type: "Polygon", coordinates: [circleCoords] },
//           },
//         });

//         mapRef.current.addLayer({
//           id: "circle-layer",
//           type: "fill",
//           source: "circle",
//           layout: {},
//           paint: {
//             "fill-color": "#585FFF",
//             "fill-opacity": 0.2,
//           },
//         });

//         addPointsLayer(bomaClusterDetails, highlightedBomaDetails);

//         const bounds = calculateBounds(circleCoords);
//         mapRef.current.fitBounds(bounds, { padding: 40 });

//         if(bomaPopupDetails){
          
//           const featureDetails = {
//             id : bomaPopupDetails.features[0].properties.id,
//             region : bomaPopupDetails.features[0].properties.region,
//             datetime : bomaPopupDetails.features[0].properties.datetime
//            }
      
//           const coordinates = {
//             latitude: bomaPopupDetails.features[0].geometry.coordinates[1],
//             longitude: bomaPopupDetails.features[0].geometry.coordinates[0]
//           }

//             createPopup({
//               mapRef: mapRef.current,
//               coordinates,
//               featureDetails,
//               isLoggedIn,
//               onReviewClick: () => {
//                 dispatch(setBomaReviewDetails(JSON.stringify(bomaPopupDetails.features[0])));
//                 dispatch(setIsReviewing(true));
//                 dispatch(setIsSidebarOpen(true));
//                 dispatch(setShowBomaCluster(false));
//                 //remove all the popups available
//                 const popupAvailable = document.getElementsByClassName('mapboxgl-popup');
//                 if ( popupAvailable.length ) {
//                   popupAvailable[0].remove();
//                 }
//               },
//             });
//         }




//         mapRef.current.on("click", "cluster-points-layer", (entryPoint) => {
//           if(!bomaPopupDetails)
//           dispatch(setBomaPopupDetails(entryPoint))
//         else{
//           let updatedBomaPopup = {
//             update:true,
//             ...bomaPopupDetails
//           }
//           dispatch(setBomaPopupDetails(updatedBomaPopup))
//         }
        
//         });

//         if (mapRef.current.getLayer("cluster-points-layer")) {
//           mapRef.current.on("click", (e) => {
//             try {
//               const features = mapRef.current.queryRenderedFeatures(e.point, {
//                 layers: ["cluster-points-layer"],
//               });
  
//               if (features.length === 0 && popupRef.current) {
//                 popupRef.current.remove();
//                 popupRef.current = null;
//               }
//             } catch (error) {
//               console.log(error)
//             }

//           });
//         }
//       }
//     }

//     return () => {
//       if (mapRef.current && mapRef.current.isStyleLoaded()) {
//         if (mapRef.current.getLayer("circle-layer")) {
//           mapRef.current.removeLayer("circle-layer");
//         }

//         if (mapRef.current.getSource("circle")) {
//           mapRef.current.removeSource("circle");
//         }

//         if (mapRef.current.getLayer("cluster-points-layer")) {
//           mapRef.current.removeLayer("cluster-points-layer");
//         }

//         if (mapRef.current.getSource("geojson-points")) {
//           mapRef.current.removeSource("geojson-points");
//         }
//       }

//       if (popupRef.current) {
//         popupRef.current.remove();
//         popupRef.current = null;
//       }
//     };
//   }, [showBomaCluster, mapRef, bomaClusterDetails, highlightedBomaDetails, bomaPopupDetails,dispatch, isLoggedIn, activePopup,addPointsLayer]);

//   const addPointsLayer = (data, highlightDetails) => {
//     console.log([data, highlightDetails]);

//     if (!highlightDetails) return;

//     // Create a new data object with updated features
//     const updatedData = {
//       ...data, // Copy the original data object
//       features: data.features.map((feature) => {
//         // For each feature, create a new object with updated properties
//         return {
//           ...feature,
//           properties: {
//             ...feature.properties,
//             latitude: feature.geometry.coordinates[0], // Assign latitude from coordinates[0]
//             longitude: feature.geometry.coordinates[1], // Assign longitude from coordinates[1]
//           },
//         };
//       }),
//     };

//     if (mapRef.current) {
//       mapRef.current.addSource("geojson-points", {
//         type: "geojson",
//         data: updatedData,
//       });

//       mapRef.current.addLayer({
//         id: "cluster-points-layer",
//         type: "circle",
//         source: "geojson-points",
//         paint: {
//           "circle-radius": [
//             "case",
//             [
//               "all",
//               [
//                 "==",
//                 ["get", "latitude"],
//                 highlightDetails.geometry.coordinates[0],
//               ],
//               [
//                 "==",
//                 ["get", "longitude"],
//                 highlightDetails.geometry.coordinates[1],
//               ],
//             ],
//             9, // Larger radius for highlighted point
//             7, // Default radius
//           ],

//           "circle-color": [
//             "case",
//             [
//               "all",
//               [
//                 "==",
//                 ["get", "latitude"],
//                 highlightDetails.geometry.coordinates[0],
//               ],
//               [
//                 "==",
//                 ["get", "longitude"],
//                 highlightDetails.geometry.coordinates[1],
//               ],
//             ],
//             "#C23D60", // Highlighted color
//             "#FFFF00", // Default color
//           ],
//           "circle-opacity": 1,
//         },
//       });
//     }
//   };

//   return null;
// };

// export default MapLayer;





import { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  calculateBounds,
  calculateCenter,
  createCircle,
  extractLatLong,
} from "../../../utils/mapUtils.js";
import { createPopup } from "../../../utils/createPopup.js";
import {
  setBomaReviewDetails,
  setIsReviewing,
  setIsSidebarOpen,
  setShowBomaCluster,
  setBomaPopupDetails,
} from "../../../store/slices/globalSlice.js";

import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

const MapLayer = ({ mapRef }) => {
  const dispatch = useDispatch();
  const popupRef = useRef(null); // Ref to track the current popup

  // Redux selectors
  const showBomaCluster = useSelector((state) => state.global.showBomaCluster);
  const bomaClusterDetails = useSelector(
    (state) => state.global.bomaClusterDetails
  );
  const bomaPopupDetails = useSelector((state) => state.global.bomaPopupDetails);
  const highlightedBomaDetails = useSelector(
    (state) => state.global.highlightedBomaDetails
  );
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  // Utility function to add points layer
  const addPointsLayer = (data, highlightDetails) => {
    if (!data || !highlightDetails) return;

    const updatedData = {
      ...data,
      features: data.features.map((feature) => ({
        ...feature,
        properties: {
          ...feature.properties,
          latitude: feature.geometry.coordinates[0],
          longitude: feature.geometry.coordinates[1],
        },
      })),
    };

    if (mapRef.current) {
      // Add source for geojson points
      if (!mapRef.current.getSource("geojson-points")) {
        mapRef.current.addSource("geojson-points", {
          type: "geojson",
          data: updatedData,
        });
      }

      // Add points layer with dynamic styling
      if (!mapRef.current.getLayer("cluster-points-layer")) {
        mapRef.current.addLayer({
          id: "cluster-points-layer",
          type: "circle",
          source: "geojson-points",
          paint: {
            "circle-radius": [
              "case",
              [
                "all",
                [
                  "==",
                  ["get", "latitude"],
                  highlightDetails.geometry.coordinates[0],
                ],
                [
                  "==",
                  ["get", "longitude"],
                  highlightDetails.geometry.coordinates[1],
                ],
              ],
              9, // Highlighted point radius
              7, // Default radius
            ],
            "circle-color": [
              "case",
              [
                "all",
                [
                  "==",
                  ["get", "latitude"],
                  highlightDetails.geometry.coordinates[0],
                ],
                [
                  "==",
                  ["get", "longitude"],
                  highlightDetails.geometry.coordinates[1],
                ],
              ],
              "#C23D60", // Highlighted color
              "#FFFF00", // Default color
            ],
            "circle-opacity": 1,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (showBomaCluster && mapRef.current && bomaClusterDetails) {
      const circleCoordinates = extractLatLong(bomaClusterDetails);

      if (circleCoordinates?.length > 0) {
        const center = calculateCenter(circleCoordinates);
        const circleCoords = createCircle(center, 5);

        // Add circle layer
        if (!mapRef.current.getLayer("circle-layer")) {
          mapRef.current.addSource("circle", {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: { type: "Polygon", coordinates: [circleCoords] },
            },
          });

          mapRef.current.addLayer({
            id: "circle-layer",
            type: "fill",
            source: "circle",
            paint: {
              "fill-color": "#585FFF",
              "fill-opacity": 0.2,
            },
          });
        }

        // Fit map bounds to the circle
        const bounds = calculateBounds(circleCoords);
        mapRef.current.fitBounds(bounds, { padding: 40 });

        // Add points layer
        addPointsLayer(bomaClusterDetails, highlightedBomaDetails);

        // Add popup if `bomaPopupDetails` exists
        if (bomaPopupDetails) {
          console.log(bomaPopupDetails)
          const { features } = bomaPopupDetails;
          const featureDetails = {
            id: features[0].properties.id,
            region: features[0].properties.region,
            datetime: features[0].properties.timeCreated,
          };
          const coordinates = {
            latitude: features[0].geometry.coordinates[1],
            longitude: features[0].geometry.coordinates[0],
          };

          createPopup({
            mapRef: mapRef.current,
            coordinates,
            featureDetails,
            isLoggedIn,
            onReviewClick: () => {
              dispatch(setBomaReviewDetails(JSON.stringify(features[0])));
              dispatch(setIsReviewing(true));
              dispatch(setIsSidebarOpen(true));
              dispatch(setShowBomaCluster(false));

              // Remove existing popups
              const popupAvailable = document.getElementsByClassName("mapboxgl-popup");
              if (popupAvailable.length) popupAvailable[0].remove();
            },
          });
        }

        // Handle cluster points click event
        mapRef.current.on("click", "cluster-points-layer", (entryPoint) => {
          if (!bomaPopupDetails) {
            dispatch(setBomaPopupDetails(entryPoint));
          } else {
            dispatch(
              setBomaPopupDetails({ update: true, ...bomaPopupDetails })
            );
          }
        });

        // Close popup on non-cluster area click
        mapRef.current.on("click", (e) => {
          const features = mapRef.current.queryRenderedFeatures(e.point, {
            layers: ["cluster-points-layer"],
          });

          if (!features.length && popupRef.current) {
            popupRef.current.remove();
            popupRef.current = null;
          }
        });
      }
    }

    // Cleanup on unmount or dependency change
    return () => {
      if (mapRef.current && mapRef.current.isStyleLoaded()) {
        ["circle-layer", "cluster-points-layer"].forEach((layer) => {
          if (mapRef.current.getLayer(layer)) {
            mapRef.current.removeLayer(layer);
          }
        });

        ["circle", "geojson-points"].forEach((source) => {
          if (mapRef.current.getSource(source)) {
            mapRef.current.removeSource(source);
          }
        });
      }

      if (popupRef.current) {
        popupRef.current.remove();
        popupRef.current = null;
      }
    };
  }, [
    showBomaCluster,
    mapRef,
    bomaClusterDetails,
    highlightedBomaDetails,
    bomaPopupDetails,
    dispatch,
    isLoggedIn,
  ]);

  return null; // No JSX as this is a functional component for managing the map
};

export default MapLayer;
