export const calculateCenter = (coords) => {
    let latSum = 0;
    let lonSum = 0;
    coords.forEach(([lon, lat]) => {
      latSum += lat;
      lonSum += lon;
    });
    return [lonSum / coords.length, latSum / coords.length];
  };
  
  //TODO - automatically find the radius based on the edge of the points

  export const createCircle = (center, radiusInKm, numPoints = 64) => {
    const coords = [];
    const earthRadius = 6371;
  
    for (let i = 0; i < numPoints; i++) {
      const angle = (i / numPoints) * (2 * Math.PI);
      const dx = radiusInKm * Math.cos(angle);
      const dy = radiusInKm * Math.sin(angle);
  
      const latitude = center[1] + (dy / earthRadius) * (180 / Math.PI);
      const longitude =
        center[0] +
        (dx / (earthRadius * Math.cos((center[1] * Math.PI) / 180))) *
          (180 / Math.PI);
  
      coords.push([longitude, latitude]);
    }
    coords.push(coords[0]);
    return coords;
  };
  
  export const calculateBounds = (coordinates) => {
    let minLat = Infinity,
      minLon = Infinity;
    let maxLat = -Infinity,
      maxLon = -Infinity;
  
    coordinates.forEach(([lon, lat]) => {
      minLat = Math.min(minLat, lat);
      minLon = Math.min(minLon, lon);
      maxLat = Math.max(maxLat, lat);
      maxLon = Math.max(maxLon, lon);
    });
  
    return [
      [minLon, minLat],
      [maxLon, maxLat],
    ];
  };
  

  export const  extractLatLong = (geojson) => {
    // Check if the input is a FeatureCollection
    if (geojson.type !== "FeatureCollection") {
        throw new Error("Invalid GeoJSON: Not a FeatureCollection");
    }

    // Initialize an array to hold the coordinates
    const coordinates = [];
    // Loop through each feature in the features array
    geojson.features.forEach(feature => {
        // Check if the feature's geometry is of type Point
        if (feature.geometry.type === "Point") {
            // Extract coordinates (longitude, latitude)
            const [longitude, latitude] = feature.geometry.coordinates;
            coordinates.push([longitude, latitude]);
        }
    });

    return coordinates;
}