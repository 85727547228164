import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  userName: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
        if(action.payload === "autoLogin"){
            const access_token = sessionStorage.getItem("accessToken");
            const refresh_token = sessionStorage.getItem("refreshToken");
            if(access_token && refresh_token){
                state.isLoggedIn = true;
            }
        }



      const { access_token, refresh_token } = action.payload;
      if (access_token && refresh_token) {
        // Storing tokens in sessionStorage
        sessionStorage.setItem("accessToken", access_token);
        sessionStorage.setItem("refreshToken", refresh_token);
        state.isLoggedIn = true;
      }

    },
    logout: (state, action) => {
      //clear the access token and refresh token

      // Removing tokens
      sessionStorage.removeItem("accessToken");
      sessionStorage.removeItem("refreshToken");

      state.isLoggedIn = false;
    },

    setUserName:(state,action)=>{
      state.userName = action.payload;
    }
  },
});

export const { login, logout, setUserName } = authSlice.actions;
export default authSlice.reducer;
