import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsReviewing,
  setImageReviewDetails,
  setBomaReviewDetails,
  setImagesNameToId,
} from "../../store/slices/globalSlice.js";

import backArrow from "../../assets/icons/backArrow.png";
import downloadIcon from "../../assets/icons/downloadIcon.png";
import ImageModal from "../modals/ImageModal";
import zoomIcon from "../../assets/icons/zoomIcon.png";
const ReviewDetails = () => {
  const dispatch = useDispatch();

  let bomaAnalysisDetails = useSelector((state) => state.global.bomaReviewDetails);
  bomaAnalysisDetails = JSON.parse(bomaAnalysisDetails);

  const bomaCattleCount = useSelector((state) => state.global.bomaCattleCount);
  const imageReviewData = useSelector((state) => state.global.imageReviewDetails);
  const imagesNameToIdDictonary = useSelector((state) => state.global.imagesNameToId);

  const [isLoadingThumbnail, setIsLoadingThumbnail] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [largeImageUrl, setLargeImageUrl] = useState(null);
  const [isLoadingLargeImage, setIsLoadingLargeImage] = useState(false);

  useEffect(() => {
    async function getDetailedBomaImagesList() {
      const URL = `${process.env.REACT_APP_AUTH_BASE_URL}images/metadata`;
      console.log("line 155", URL);
      try {
        const queryParam = new URLSearchParams({
          collection_id: bomaAnalysisDetails?.properties?.id,
        });

        const response = await fetch(`${URL}?${queryParam}`, {
          method: "GET",
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const imagesIdName = {};
        data.forEach((item) => {
          imagesIdName[item.name] = item.id;
        });

        dispatch(setImagesNameToId(imagesIdName));
      } catch (error) {
        console.error("There was an error with the request:", error);
      }
    }

    getDetailedBomaImagesList();
  }, [bomaAnalysisDetails?.properties?.id, dispatch]);

  useEffect(() => {
    async function getImageThumbnail() {
      if(!imageReviewData) return;

      const imageId= imagesNameToIdDictonary[imageReviewData.properties.imageName];
      const URL = `${process.env.REACT_APP_AUTH_BASE_URL}analytics/results/images/${imageId}/blob`;

      const accessToken = sessionStorage.getItem("accessToken");


      try {
        setIsLoadingThumbnail(true); // Start loading state

        const queryParam = new URLSearchParams({
          // id: imagesNameToIdDictonary[imageReviewData.properties.imageName],
          thumbnail: true,
        });

        const response = await fetch(`${URL}?${queryParam}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "image/png",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const imageBlob = await response.blob();
        const imageUrl = window.URL.createObjectURL(imageBlob);
        setThumbnailUrl(imageUrl);
      } catch (error) {
        console.log("There was an error with the request:", error);
      } finally {
        setIsLoadingThumbnail(false); // End loading state
      }
    }

    getImageThumbnail();
  }, [imageReviewData, imagesNameToIdDictonary]);


  const handleBackButtonClick = () => {
    dispatch(setIsReviewing(false));
    dispatch(setBomaReviewDetails(null));
    dispatch(setImageReviewDetails(null));
  };

  const handleImageClick = async () => {
    setIsModalOpen(true);
    setIsLoadingLargeImage(true);

    const URL = `${process.env.REACT_APP_AUTH_BASE_URL}images/blob`;
    const accessToken = sessionStorage.getItem("accessToken");

    try {
      const queryParam = new URLSearchParams({
        id: imagesNameToIdDictonary[imageReviewData.properties.imageName],
      });

      const response = await fetch(`${URL}?${queryParam}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: "image/png",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const imageBlob = await response.blob();
      const imageUrl = window.URL.createObjectURL(imageBlob);
      setLargeImageUrl(imageUrl);
    } catch (error) {
      console.error("There was an error with the request:", error);
    } finally {
      setIsLoadingLargeImage(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setLargeImageUrl(null);
  };

  return (
    <div
      className="text-white w-72 h-full overflow-y-auto"
      style={{ background: "#1E1E20" }}
    >
      <img
        src={backArrow}
        alt="back button"
        className="mt-8 ml-3 h-4 cursor-pointer"
        onClick={handleBackButtonClick}
      />

      <div className="mt-2 p-3">
        <div className="flex justify-between items-center">
          <p className="text-2xl pb-2">Reviewing</p>
          <img
            src={downloadIcon}
            alt="download icon"
            className="cursor-pointer h-6"
          />
        </div>
        <p>
          <strong>Boma ID:</strong> {bomaAnalysisDetails.properties.id}
        </p>
        <p>
          <strong>Make:</strong> {bomaAnalysisDetails.properties.make}
        </p>
        <p>
          <strong>Model:</strong> {bomaAnalysisDetails.properties.model}
        </p>
        <p>
          <strong>Region:</strong> {bomaAnalysisDetails.properties.region}
        </p>
        <p>
          <strong>Total Cattle:</strong>{" "}
          {bomaCattleCount &&
          bomaCattleCount.hasOwnProperty(bomaAnalysisDetails.properties.id)
            ? bomaCattleCount[bomaAnalysisDetails.properties.id]
            : `N/A`}
        </p>
        <p>
          <strong>Date:</strong> {bomaAnalysisDetails.properties.timeCreated}
        </p>
        <p>
          <strong>Latitude:</strong>{" "}
          {bomaAnalysisDetails.geometry.coordinates[0]}
        </p>
        <p>
          <strong>Longitude:</strong>{" "}
          {bomaAnalysisDetails.geometry.coordinates[1]}
        </p>
      </div>

      <div className="text-xl my-4 p-3"></div>

      <div className="border-b border-b-gray-500 py-2 w-full"></div>

      {imageReviewData && (
        <div className="mt-2 p-3">
          <p>
            <strong>Image Title:</strong> {imageReviewData.properties.imageName}
          </p>
          <p>
            <strong>Latitude:</strong> {imageReviewData.geometry.coordinates[0]}
          </p>
          <p>
            <strong>Longitude:</strong>{" "}
            {imageReviewData.geometry.coordinates[1]}
          </p>
          {isLoadingThumbnail ? (
            <div className="flex justify-center items-center mt-4 h-40">
              {" "}
              {/* Container to center */}
              <div className="w-10 h-10 border-4 border-t-4 border-t-blue-500 border-gray-200 rounded-full animate-spin"></div>

            </div>
          ) : (
            <>
              <div className="relative inline-blockpt-2  mx-auto mt-4 ">
                {" "}
                {/* relative positioning wrapper */}
                <img src={thumbnailUrl} alt="thumbnail" className="" />
                <img
                  src={zoomIcon}
                  alt="zoom"
                  className="absolute top-2 right-2 w-8 cursor-pointer" // absolute positioning for top-right corner
                  onClick={handleImageClick}
                />
              </div>
            </>
          )}
        </div>
      )}
      <ImageModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        imageUrl={largeImageUrl}
        isLoading={isLoadingLargeImage}
        imgName={imageReviewData?.properties?.imageName}
        bomaId={bomaAnalysisDetails?.properties?.id}
      />
    </div>
  );
};

export default ReviewDetails;
