import React from "react";
import { MenuIcon } from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";
import { setIsSidebarOpen } from "../../store/slices/globalSlice";
import Sidebar from "./Sidebar";

const SideMenu = () => {
  const isSidebarOpen = useSelector((state) => state.global.isSidebarOpen);
  const dispatch = useDispatch();

  const toggleMenu = () => dispatch(setIsSidebarOpen(!isSidebarOpen));

  return (
    <div className="fixed top-0 left-0 h-full flex z-10">
      {isSidebarOpen && <Sidebar />}
      <button
        onClick={toggleMenu}
        className={`absolute top-0 p-2 z-10 bg-gray-800 text-white rounded-r-sm hover:bg-gray-700 focus:outline-none transition-all duration-300 ease-in-out ${
          isSidebarOpen ? "left-72" : "left-0"
        }`}
      >
        <MenuIcon className="w-6 h-6" />
      </button>
    </div>
  );
};

export default SideMenu;
