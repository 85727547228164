import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import Button from "../components/authComponents/Button.jsx";
import Input from "../components/authComponents/Input.jsx";
import { toast } from "react-toastify";

import authService from "../utils/auth.js";
import { login as authLogin } from "../store/slices/authSlice.js";

import userIcon from "../assets/icons/username.png";
import lockIcon from "../assets/icons/lock.png";
import emailIcon from "../assets/icons/email.png";

import axios from "axios";

const UpdatePassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;

  //   const create = async (data) => {
  //     console.log("Input data -->", data);

  //     // Clear previous errors and set initial state
  //     setError("");
  //     setIsLoading(true);

  //     // Validate input data
  //     if (!data) {
  //       setError("Input data is required");
  //       setIsLoading(false);
  //       return;
  //     }

  //     try {
  //       const userData = await authService.createAccount(data);
  //       if (!userData) {
  //         setError("No response from server");
  //         return; // Early return to avoid further processing
  //       }

  //       const { access_token, refresh_token} = userData;

  //       if (access_token && refresh_token) {
  //         toast.success("SIGNUP SUCCESSFUL");
  //         dispatch(authLogin(userData));
  //         navigate("/");
  //       } else {
  //         setError("Signup failed, please try again");
  //       }
  //     } catch (error) {
  //       console.error("Error in signup:", error);
  //       const [type, message] = Object.entries(error)[0];
  //       setError(message);
  //     } finally {
  //       // Ensure loading state is updated regardless of success or failure
  //       setIsLoading(false);
  //     }
  //   };

  const url = new URL(window.location.href);

  // Use URLSearchParams to get the 'token' parameter

  useEffect(()=>{
    const tokenParam = url.searchParams.get("token");
    if(!tokenParam){
      console.log("No token param is there")
    navigate("/")
  }})

  // try {
  //   const tokenParam = url.searchParams.get("token");
  //   if(!tokenParam){
  //     console.log("No token param is there")
  //   navigate("/")

  //   }
  // } catch (error) {
  //   console.log(error);
  //   navigate("/")
  // }




  const validatePassword = (data) => {
    const { password, confirmPassword } = data;
    return password === confirmPassword;
  };

  const UpdatePassword = async (data) => {
    console.log(window.location.href);

    console.log("Input data -->", data);

    // Clear previous errors and set initial state
    setError("");
    setIsLoading(true);
    if (!data) {
      setError("Input data is required");
      setIsLoading(false);
      return;
    }

    if (!validatePassword(data)) {
      setError("Password do not match");
      return;
    }

    try {
      const url = new URL(window.location.href);

      // Use URLSearchParams to get the 'token' parameter
      const tokenParam = url.searchParams.get("token");
      if (tokenParam) {
        const response = await axios.post(
          `${BASE_URL}auth/forgotpass/${tokenParam}`,
          {
            password: data.password,
          }
        );

        if (response.status == 200) {
          toast.success("Password Updated");
          //   dispatch(authLogin(userData));
          navigate("/");
        } else {
          setError("Updation failed, please try again later");
        }
      }
    } catch (error) {
      console.error("Error in signup:", error);
      const [type, message] = Object.entries(error)[0];
      setError(message);
    } finally {
      // Ensure loading state is updated regardless of success or failure
      setIsLoading(false);
    }
  };

  return (
    <div
      className="flex items-center justify-center min-h-screen"
      style={{ background: "#1E1E20" }}
    >
      <div
        className="w-full max-w-md bg-gray-800 p-10 rounded-xl transform"
        style={{ background: "#1E1E20" }}
      >
        <h2 className="text-center text-3xl font-bold text-gray-200 mb-6">
          EyeClimate Inc
        </h2>
        <h3 className="text-center text-2xl  text-gray-200 mb-6">
          Update Password
        </h3>

        {error && <p className="text-red-500 text-center mb-6">{error}</p>}

        <form onSubmit={handleSubmit(UpdatePassword)} className="space-y-6">
          <div className="space-y-5">
            {/* <div className="space-y-1">
              <label className="block text-gray-300">Email</label>
              <div className="relative">
                <img
                  src={emailIcon}
                  alt="email icon"
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5"
                />
                <Input
                  placeholder="Email"
                  {...register("email", { required: true })}
                  className="block w-full p-3 pl-12 bg-black text-gray-200 border border-gray-600 rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:bg-gray-700 focus:border-transparent"
                />
              </div>
            </div> */}

            <div className="space-y-1">
              <label className="block text-gray-300">New Password</label>
              <div className="relative">
                <img
                  src={lockIcon}
                  alt="lock icon"
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5"
                />
                <Input
                  placeholder="Password"
                  type="password"
                  {...register("password", { required: true })}
                  className="block w-full p-3 pl-12 bg-black text-gray-200 border border-gray-600 rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:bg-gray-700 focus:border-transparent"
                />
              </div>
            </div>

            <div className="space-y-1">
              <label className="block text-gray-300">Confirm Password</label>
              <div className="relative">
                <img
                  src={lockIcon}
                  alt="lock icon"
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5"
                />
                <Input
                  placeholder="Confirm password"
                  type="password"
                  {...register("confirmPassword", { required: true })}
                  className="block w-full p-3 pl-12 bg-black text-gray-200 border border-gray-600 rounded-md shadow-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:bg-gray-700 focus:border-transparent"
                />
              </div>
            </div>

            <div className="border-b border-b-gray-500"></div>

            <Button
              type="submit"
              className="w-full py-2 px-6 font-bold text-gray-100 bg-indigo-600 rounded-xl hover:bg-indigo-700 transition-all duration-300 shadow-md hover:shadow-lg focus:ring-2 focus:ring-indigo-500"
            >
              {isLoading ? "Updating ..." : "Update"}
            </Button>
          </div>

          <p className="text-center text-gray-400 mb-8">
            Already have an account?&nbsp;
            <Link to="/login" className="text-indigo-400 hover:underline">
              Sign In
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default UpdatePassword;
