// import React, { useEffect, useState } from "react";
// import { ChevronRightIcon } from "@heroicons/react/solid";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   setShowBomaCluster,
//   setBomaClusterDetails,
//   setHighlightedBomaDetails,
//   setBomaPopupDetails,
// } from "../../store/slices/globalSlice";
// import { groupGeoJSONByDate } from "../../utils/helperFun";

// const EarthquakeList = () => {
//   const [expandedDate, setExpandedDate] = useState(null); // Track which date group is expanded
//   const [clickedItemId, setClickedItemId] = useState(null); // Track clicked item ID

//   const bomaCattleCount = useSelector((state) => state.global.bomaCattleCount);

//   const dispatch = useDispatch();

//   const filteredData = useSelector((state) => state.global.filteredData);

//   // Group data by date
//   const groupedDataByDate = groupGeoJSONByDate(filteredData);
//   console.log("GROUPED DATE DAT", groupedDataByDate);

//   const availableBomaDataLenght = Object.keys(groupedDataByDate).length;



//   function handleSideMenuClick(feature, entry) {
//     const bomaClusterInputData = {
//       type: "FeatureCollection",
//       crs: {
//         type: "name",
//         properties: {
//           name: "urn:ogc:def:crs:OGC:1.3:CRS84",
//         },
//       },
//       features: feature,
//     };

//     const popupDetails = {
//       type: "FeatureCollection",
//       crs: {
//         type: "name",
//         properties: {
//           name: "urn:ogc:def:crs:OGC:1.3:CRS85",
//         },
//       },
//       features: [entry],
//     };

//     console.log("CLICKED SIDE MENU TO ZOOM ON POPUP")
//     console.log("FEATURES ", feature);
//     console.log("ENTRY  ", entry);
//     dispatch(setBomaPopupDetails(popupDetails));

//     dispatch(setBomaClusterDetails(bomaClusterInputData));
//     dispatch(setShowBomaCluster(true));
//     dispatch(setHighlightedBomaDetails(entry));
//     setClickedItemId(entry.properties.id); // Update state with clicked item ID
//   }

//   function trimTo4Decimals(number) {
//     return Number(number.toFixed(4));
//   }

//   useEffect(() => {
//     console.log("DATA CHANGED IN EARTHQUAKE LIST");
//     console.log(filteredData);
//   }, [filteredData]);

//   return (
//     <div className="space-y-1 rounded-md shadow-md max-w-md mx-auto">
//       {/* Loop through each date group */}

//       {availableBomaDataLenght === 0 ? (
//         <p className="p-4">No boma data availabe</p>
//       ) : (
//         groupedDataByDate.map(({ date, features }) => (
//           <div key={date}>
//             {/* Date Header */}
//             <div
//               className="flex items-center justify-between cursor-pointer p-3 bg-[#585FFF66] "
//               onClick={() =>
//                 setExpandedDate(expandedDate === date ? null : date)
//               }
//             >
//               <div className="flex items-center space-x-2">
//                 <input
//                   type="checkbox"
//                   className="form-checkbox text-[#A3A3FF]  bg-[#2A2A40] border-[#3A3A6A]"
//                 />
//                 <p className="text-gray-300 text-sm font-medium">{date}</p>
//               </div>
//               <ChevronRightIcon
//                 className={`w-4 h-4 text-gray-400 transform transition-transform ${
//                   expandedDate === date ? "rotate-90" : ""
//                 }`}
//               />
//             </div>

//             {/* Expanded Date Group - List of Entries for this date */}
//             {expandedDate === date && (
//               <div>
//                 {features.map((entry) => (
//                   <div
//                     key={entry.properties.id}
//                     className={`transition-all duration-200 p-3 border border-[#3A3A6A] bg-[#1B1B2F]
//                    ${
//                      clickedItemId === entry.properties.id ? "bg-[#2D2D44]" : ""
//                    }
                  
//                   `}
//                   >
//                     <div className="flex items-center justify-between">
//                       {/* Left Side: Checkbox and ID */}
//                       <div className="flex items-center space-x-2">
//                         <input
//                           type="checkbox"
//                           className="form-checkbox text-[#A3A3FF] rounded-sm bg-[#2A2A40] border-[#3A3A6A]"
//                         />
//                       </div>

//                       {/* Right Side: Region */}
//                       <div className="flex items-center space-x-2"></div>
//                     </div>

//                     {/* Automatically Expanded Content for the Entry */}
//                     <div
//                       onClick={() => handleSideMenuClick(features, entry)}
//                       className={`mt-3 pl-6 space-y-1 rounded-md cursor-pointer`}
//                     >
//                       <div className="p-1">
//                         <p className="text-gray-400 text-sm">
//                           <strong className="text-gray-300">Boma ID:</strong>{" "}
//                           {entry.properties.id}
//                         </p>
//                         <p className="text-gray-400 text-sm">
//                           <strong className="text-gray-300">Location:</strong>{" "}
//                           {entry.properties.region}
//                         </p>

//                         <p className="text-gray-400 text-sm">
//                           <strong className="text-gray-300">
//                             Total cattle:
//                           </strong>{" "}
//                           {bomaCattleCount &&
//                           bomaCattleCount.hasOwnProperty(entry.properties.id)
//                             ? bomaCattleCount[entry.properties.id]
//                             : `N/A`}
//                         </p>
//                         <p className="text-gray-400 text-sm">
//                           <strong className="text-gray-300">Latitude:</strong>{" "}
//                           {trimTo4Decimals(entry.geometry.coordinates[1]) +
//                             " N"}
//                         </p>
//                         <p className="text-gray-400 text-sm">
//                           <strong className="text-gray-300">Longitude:</strong>{" "}
//                           {trimTo4Decimals(entry.geometry.coordinates[0]) +
//                             " W"}
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             )}
//           </div>
//         ))
//       )}
//     </div>
//   );
// };

// export default EarthquakeList;






import React, { useEffect, useState } from "react";
import { ChevronRightIcon, DotsVerticalIcon } from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowBomaCluster,
  setBomaClusterDetails,
  setHighlightedBomaDetails,
  setBomaPopupDetails,
  
  setShowDeleteBomaModal,
  setBomaIdToBeDeleted,
  setAnalysisJobList,
  setJobStatusList
} from "../../store/slices/globalSlice";
import { groupGeoJSONByDate } from "../../utils/helperFun";
import axios from "axios";

const EarthquakeList = () => {
  const [expandedDate, setExpandedDate] = useState(null); // Track which date group is expanded
  const [clickedItemId, setClickedItemId] = useState(null); // Track clicked item ID
  const [openMenuId, setOpenMenuId] = useState(null); // Track which menu is open

  const bomaCattleCount = useSelector((state) => state.global.bomaCattleCount);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const dispatch = useDispatch();

  const filteredData = useSelector((state) => state.global.filteredData);

  // Group data by date
  const groupedDataByDate = groupGeoJSONByDate(filteredData);

  const availableBomaDataLength = Object.keys(groupedDataByDate).length;

  const jobStatusList = useSelector((state) => state.global.jobStatusList); //current job status
  const analysisJobList = useSelector((state) => state.global.analysisJobList); //list of jobs

  const BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;
  const accessToken = sessionStorage.getItem("accessToken");

  //analysis api calls 

  if(analysisJobList.length > 0 ){

  setInterval(async () => {
    console.log("POLLING CALL FOR UPDATED RESULTS");
    try {
      // Make the API call
      const response = await fetch(`${BASE_URL}analytics/collections`, {
        method: "GET",
        headers: { Authorization: `Bearer ${accessToken}` },
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.statusText}`);
      }
  
      const data = await response.json();
  
      // Process the data
      let pendingListOfJobs = []
      data.forEach((item) => {
        const bomaId = item.id;
        pendingListOfJobs.push(bomaId);
      });
      
      const completedBomaJobs = analysisJobList.filter((item) => !pendingListOfJobs.includes(item));
      const updatedJobStatus = {...jobStatusList};
      completedBomaJobs.forEach((item) => {
        updatedJobStatus[item] = "completed";
      })
      console.log([pendingListOfJobs, analysisJobList])

      if(pendingListOfJobs.length !== analysisJobList.length){
        dispatch(setAnalysisJobList(pendingListOfJobs));
        // Update the job status list
        dispatch(setJobStatusList(updatedJobStatus));
      }

      

    } catch (error) {
      console.error("Failed to fetch job status:", error);
    }
  }, 15000);
  
}









  function handleSideMenuClick(feature, entry) {
    const bomaClusterInputData = {
      type: "FeatureCollection",
      crs: {
        type: "name",
        properties: {
          name: "urn:ogc:def:crs:OGC:1.3:CRS84",
        },
      },
      features: feature,
    };

    const popupDetails = {
      type: "FeatureCollection",
      crs: {
        type: "name",
        properties: {
          name: "urn:ogc:def:crs:OGC:1.3:CRS85",
        },
      },
      features: [entry],
    };

    console.log("CLICKED SIDE MENU TO ZOOM ON POPUP");
    console.log("FEATURES ", feature);
    console.log("ENTRY  ", entry);
    dispatch(setBomaPopupDetails(popupDetails));

    dispatch(setBomaClusterDetails(bomaClusterInputData));
    dispatch(setShowBomaCluster(true));
    dispatch(setHighlightedBomaDetails(entry));
    setClickedItemId(entry.properties.id); // Update state with clicked item ID
  }

  function handleDeleteClick(bomaId){
    console.log("DELETE BOMA CLICKED")

    //open modal 
    dispatch(setShowDeleteBomaModal(true));
    dispatch(setBomaIdToBeDeleted(bomaId));

    setOpenMenuId(null); // Close the menu
  }

  async function handleAnalyzeClick(bomaId){
    console.log("ANALYZE BOMA CLICKED")

    const BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;
    const token = sessionStorage.getItem("accessToken");

    //make a request to do analysis
    try {

      const response = await axios.post(
        `${BASE_URL}analytics/collections/${bomaId}`,
        {
          module: process.env.REACT_APP_ANALYSIS_MODULE,
          apiurl: process.env.REACT_APP_ANALYSIS_API_URL,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("ANALYTICS RESPONSE", response);
      dispatch(setAnalysisJobList(bomaId));


    } catch (error) {
      console.error("Error in analysis", error)
    }

    //toast to show analysis started

    setOpenMenuId(null); // Close the menu

  }

  function trimTo4Decimals(number) {
    if(!number) return "N/A";
    return Number(number.toFixed(4));
  }

  useEffect(() => {
    console.log("DATA CHANGED IN EARTHQUAKE LIST");
    console.log(filteredData);
  }, [filteredData]);

  
  return (
    <div className="space-y-1 rounded-md shadow-md max-w-md mx-auto">
      {/* Display when no data is available */}
      {availableBomaDataLength === 0 ? (
        <p className="p-4">No boma data available</p>
      ) : (
        groupedDataByDate.map(({ date, features }) => (
          <div key={date}>
            {/* Date Header */}
            <div
              className="flex items-center justify-between cursor-pointer p-3 bg-[#585FFF66]"
              onClick={() =>
                setExpandedDate(expandedDate === date ? null : date)
              }
            >
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  className="form-checkbox text-[#A3A3FF] bg-[#2A2A40] border-[#3A3A6A]"
                />
                <p className="text-gray-300 text-sm font-medium">{date}</p>
              </div>
              <ChevronRightIcon
                className={`w-4 h-4 text-gray-400 transform transition-transform ${
                  expandedDate === date ? "rotate-90" : ""
                }`}
              />
            </div>
  
            {/* Expanded Date Group - List of Entries for this date */}
            {expandedDate === date && (
              <div>
                {features.map((entry) => {
                  // Determine status using dictionary
                  const entryStatus = jobStatusList[entry.properties.id] || "not-started";
  
                  return (
                    <div
                      key={entry.properties.id}
                      className={`transition-all duration-200 p-3 border border-[#3A3A6A] bg-[#1B1B2F]
                        ${
                          clickedItemId === entry.properties.id
                            ? "bg-[#2D2D44]"
                            : ""
                        }`}
                    >
                      <div className="flex items-center justify-between">
                        {/* Left Side: Checkbox */}
                        <div className="flex items-center space-x-2">
                          <input
                            type="checkbox"
                            className="form-checkbox text-[#A3A3FF] rounded-sm bg-[#2A2A40] border-[#3A3A6A]"
                          />
                        </div>

                                              {/* Status Tag */}
                      {
                        isLoggedIn &&
                                             
                      <div className="flex justify-center space-x-2">
                        <span
                          className={`px-4 py-1 text-xs font-semibold rounded-lg ${
                            entryStatus === "completed"
                              ? "bg-green-600 text-white"
                              : entryStatus === "not-started"
                              ? "bg-gray-500 text-white"
                              : entryStatus === "in-progress"
                              ? "bg-yellow-500 text-black"
                              : "bg-blue-500 text-white"
                          }`}
                        >
                          {entryStatus === "completed"
                            ? "Completed"
                            : entryStatus === "pending"
                            ? "Pending"
                            : entryStatus === "in-progress"
                            ? "In Progress"
                            : "Not Started"}
                        </span>
                      </div>
                }
                        {/* Right Side: 3-dot Menu */}
                        <div className="relative">
                          <DotsVerticalIcon
                            className="w-5 h-5 text-gray-400 cursor-pointer"
                            onClick={() =>
                              setOpenMenuId(
                                openMenuId === entry.properties.id
                                  ? null
                                  : entry.properties.id
                              )
                            }
                          />
                          {openMenuId === entry.properties.id && (
                            <div className="absolute right-0 mt-2 w-30 bg-[#2A2A40] border border-[#3A3A6A] rounded-sm shadow-lg z-50">
                              <button
                                className="block w-full px-4 py-2 text-left text-sm text-gray-300 hover:bg-[#585FFF66]"
                                onClick={() => handleAnalyzeClick(openMenuId)}
                              >
                                Analyze
                              </button>
                              <button
                                className="block w-full px-4 py-2 text-left text-sm text-gray-300 hover:bg-red-500"
                                onClick={() => handleDeleteClick(openMenuId)}
                              >
                                Delete
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
  
                      {/* Automatically Expanded Content for the Entry */}
                      <div
                        onClick={() => handleSideMenuClick(features, entry)}
                        className={`mt-3 pl-6 space-y-1 rounded-md cursor-pointer`}
                      >
                        <div className="p-1">
                          <p className="text-gray-400 text-sm">
                            <strong className="text-gray-300">Boma ID:</strong>{" "}
                            {entry.properties.id}
                          </p>
                          <p className="text-gray-400 text-sm">
                            <strong className="text-gray-300">Location:</strong>{" "}
                            {entry.properties.region}
                          </p>
                          <p className="text-gray-400 text-sm">
                            <strong className="text-gray-300">Total cattle:</strong>{" "}
                            {bomaCattleCount &&
                            bomaCattleCount.hasOwnProperty(entry.properties.id)
                              ? bomaCattleCount[entry.properties.id]
                              : `N/A`}
                          </p>
                          <p className="text-gray-400 text-sm">
                            <strong className="text-gray-300">Latitude:</strong>{" "}
                            {trimTo4Decimals(entry?.geometry?.coordinates[1]) +
                              " N"}
                          </p>
                          <p className="text-gray-400 text-sm">
                            <strong className="text-gray-300">Longitude:</strong>{" "}
                            {trimTo4Decimals(entry?.geometry?.coordinates[0]) +
                              " W"}
                          </p>
                        </div>
                      </div>
  

                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
}  
export default EarthquakeList;
