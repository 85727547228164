import React from "react";

const NoInternet = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-gray-800 p-8 rounded-md text-center shadow-lg border border-gray-600"
      style={{ background: "#1E1E20" }}
      >
        <h2 className="text-2xl font-bold text-white mb-4">No Internet Connection</h2>
        <p className="text-gray-300 mb-6">
          Please check your connection and try again.
        </p>
        <button
          onClick={onClose}
          className="block w-full p-2 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700 transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Retry
        </button>
      </div>
    </div>
  );
};

// 

export default NoInternet;
