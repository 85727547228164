import axios from "axios";


function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!email) {
      throw {email : "Email is required."};
  }
  if (!emailRegex.test(email)) {
      throw {email: "Invalid email format."};
  }
}

// Function to validate password
function validatePassword(password) {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%_]{6,64}$/;

  if (!password) {
      throw {password: "Password is required."};
  }
  if (!passwordRegex.test(password)) {
      throw {  password:
        "Password must be 6-64 characters long, include uppercase and lowercase letters, a digit, and can contain special characters !@#$%_."
      }
      ;
  }
}


// Combined Registration Validation
function validateRegistration(email, password) {
  try {
      validateEmail(email);
      validatePassword(password);
      console.log("Registration validated!");
  } catch (error) {
      console.log("Validation Error:", error);
      throw error; // Re-throw the error to propagate it further if needed
  }
}


export class AuthService {
  async makeRequest(endpoint, method = "POST", data = {}, requiresAuth = false) {
    const url = `${process.env.REACT_APP_AUTH_BASE_URL}${endpoint}`;
    const headers = {};

    if (requiresAuth) {
      const token = sessionStorage.getItem("accessToken");
      if (!token) throw { token: "Unauthorized: No token found"};
      headers.Authorization = `Bearer ${token}`;
    }

    try {
      const response = await axios({ url, method, data, headers });
      return response.data;
    } catch (error) {
      console.log(error)
      if(error.response){
        throw error.response.data.errors;
      }else{
        throw{unexpected: "Something went wrong, try again later"};
      }


    }
  }

  storeTokens({ accessToken, refreshToken }) {
    sessionStorage.setItem("accessToken", accessToken);
    sessionStorage.setItem("refreshToken", refreshToken);
  }

  clearTokens() {
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
  }

  async createAccount({ email, password, username, confirmPassword }) {
      validateRegistration(email, password);
      console.log("Registration successful!");


    if (password !== confirmPassword) {
      throw {password: "Passwords do not match." };
    }

    console.log("SIGNUP CALLED IN AUTH");

    const response = await this.makeRequest("auth/register", "POST", { email, username, password });

    console.log("Signup response:", response);

    // Optionally auto-login after signup
    return response;
  }

  async login({ username, password }) {
    console.log("LOGIN CALLED IN AUTH");

    const response = await this.makeRequest("auth/login", "POST", { user: username, password });

    console.log("Login response:", response);

    this.storeTokens({
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
    });

    console.log("login dome")
    return response;
  }

  async logout() {
    console.log("LOGOUT CALLED IN AUTH");

    const response = await this.makeRequest("auth/logout", "GET", {}, true);

    console.log("Logout response:", response);

    this.clearTokens();

    return response;
  }
}

const authService = new AuthService();
export default authService;
