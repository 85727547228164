import React, { useState, useRef, useEffect } from "react";
import { DateRange } from "react-date-range";
import { parse, format, isWithinInterval } from "date-fns";
import "react-date-range/dist/styles.css"; // Required for styling
import "react-date-range/dist/theme/default.css"; // Required for styling

import { useSelector, useDispatch } from "react-redux";
import { setFilteredData } from "../../store/slices/globalSlice";

import calenderIcon from "../../assets/icons/calenderIcon.png";

const DateRangeFilter = () => {
  const dispatch = useDispatch();
  const userBomaDetails = useSelector((state) => state.global.userBomaData);

  const [range, setRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [open, setOpen] = useState(false);

  console.log("BOMA DETAILS", userBomaDetails)

  const applyDateFilter = () => {
    if (range[0].startDate && range[0].endDate) {
      const filtered = userBomaDetails.features.filter((entry) => {
        const entryDate = parse(
          entry.properties.timeCreated,
          "EEE, dd MMM yyyy HH:mm:ss 'GMT'",
          new Date()
        );
        return isWithinInterval(entryDate, {
          start: range[0].startDate,
          end: range[0].endDate,
        });
      });

      const updatedData = {
        crs: userBomaDetails.crs,
        type: userBomaDetails.type,
        features: filtered,
      };

      dispatch(setFilteredData(updatedData));
      setOpen(false);
    }
  };

  const resetSelection = () => {
    setRange([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ])
    setOpen(false)
    dispatch(setFilteredData(userBomaDetails));
    
  }


  return (
    <div className="relative">
      <div
        className="flex items-center bg-black rounded-md"
        onClick={() => setOpen(true)}
      >
        <input
          value={
            range[0].startDate && range[0].endDate
              ? `${format(range[0].startDate, "MM/dd/yyyy")} to ${format(
                  range[0].endDate,
                  "MM/dd/yyyy"
                )}`
              : "Select date range"
          }
          readOnly
          className="w-full bg-black text-gray-400 p-2 pl-4 rounded-l outline-none"
          placeholder="Select date range"
        />
        <img src={calenderIcon} alt="calender" className="w-4 mr-3" />
      </div>

      {open && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-70">
          {/* Modal Content */}
          <div className="bg-gray-100 rounded-lg shadow-md w-full max-w-sm p-4">
            {/* Header */}
            <h2 className="text-md font-medium text-center mb-3 text-gray-700">
              Select Date Range
            </h2>

            {/* Date Range Picker */}
            <DateRange
              editableDateInputs={true}
              onChange={(item) => setRange([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={range}
              months={1}
              direction="vertical"
              className="border border-gray-300 rounded-md shadow-sm"
            />

   {/* Modal Actions */}
   <div className="flex justify-between items-center mt-6 space-x-2">
        <button
          onClick={() => setOpen(false)}
          className="flex-1 bg-gray-200 text-gray-600 font-medium py-2 px-4 rounded-md hover:bg-gray-300 transition-all duration-200"
        >
          Cancel
        </button>

        <button
          onClick={resetSelection}
          className="flex-1 bg-blue-100 text-blue-500 font-medium py-2 px-4 rounded-md hover:bg-blue-200 transition-all duration-200"
        >
          Reset
        </button>

        <button
          onClick={applyDateFilter}
          className={`flex-1 py-2 px-4 rounded-md text-white font-medium transition-all duration-200 ${
            range[0].startDate && range[0].endDate
              ? "bg-blue-500 hover:bg-blue-600"
              : "bg-blue-300 cursor-not-allowed"
          }`}
          disabled={!range[0].startDate || !range[0].endDate}
        >
          Apply
        </button>
      </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangeFilter;
