const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <div className="fixed bottom-0 left-1/2 transform -translate-x-1/2 mb-1 bg-gray-700 text-white bg-opacity-50 px-4 py-1 rounded-md text-center">
      &copy; {currentYear} EyeClimate Inc. All rights reserved.
    </div>
  );
};

export default Footer;
