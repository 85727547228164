import { createSlice } from "@reduxjs/toolkit";

const initialState = {


  userBomaData : null,

  filteredData: [],
  isUploading: false,
  totalUploads: 0,
  isAnalysing: false,
  showUploadModal: false,
  collectionId: null,

  isSidebarOpen: false,

  //files
  selectedFiles: [],
  uploadStatus: [],
  failedUploads: [],
  successfulUploads: 0,
  totalFiles: 0,


  //circle coordinates
  circleCoordinates : [
    [77.2090,28.6139],
    [77.2100,28.6150],
    [77.2050,28.6100],
    [77.2120,28.6120]
  ],



  //BOMA CLUSTER
  showBomaCluster : false,
  bomaClusterDetails: null,




  


  //REVIEW BOMA DETAILs
  isReviewing : false,
  bomaReviewDetails: null,
  imageReviewDetails: null,

  bomaAnalysisDetails: null,




  //review images name to id dictionary
  imagesNameToId: null,

  highlightedBomaDetails: null,
  highlightedImageDetails: null,



  bomaCattleCount:null,

  bomaPopupDetails : null,





  activePopup : null,





  showDeleteBomaModal: false, //model to confirm the boma deletion
  bomaIdToBeDeleted: null,


  //state to reload the app
  bomaCreated: 0,
  bomaDeleted: 0,

  jobStatusList: {},



  //analysis job list
  analysisJobList: [],





  uploadSuccessful:false
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setUserBomaData: (state, action) => {
      state.userBomaData = action.payload;
    },

    setFilteredData: (state, action) => {
      state.filteredData = action.payload;

    },
    setIsUploading: (state, action) => {
      state.isUploading = action.payload;
    },
    setTotalUploads: (state, action) => {
      state.totalUploads = action.payload;
    },
    setIsAnalysing: (state, action) => {
      state.isAnalysing = action.payload;
    },
    setShowUploadModal: (state, action) => {
      state.showUploadModal = action.payload;
    },


    setIsSidebarOpen: (state,action) => {
      state.isSidebarOpen = action.payload;
    },




    setCollectionId: (state,action) => {
      state.collectionId = action.payload;
      console.log("COLLECTION ID IN STORE", state.collectionId);
    },








    //Files
    setSelectedFiles: (state, action) => {
      state.selectedFiles = action.payload;
      console.log("SELECTED FILES STORE", state.selectedFiles)
    },
    setUploadStatus: (state, action) => {
      // console.log("upload status in store ", action.payload);

      const incomingFileName = action.payload.name;  // Assuming each status has a unique id or filename

      // Find the index of the item with the same id (or another unique identifier)
      const existingIndex = state.uploadStatus.findIndex(item => item.name === incomingFileName);
    
      if (existingIndex !== -1) {
        // Update the status if the item already exists
        state.uploadStatus[existingIndex] = action.payload;
      } else {
        // Add new status if the item doesn't exist
        state.uploadStatus.push(action.payload);
      }





      // state.uploadStatus = [...state.uploadStatus ,action.payload];
      // console.log("upload status store", state.uploadStatus);
    },
    setSuccessfulUploads: (state, action) => {
      state.successfulUploads = action.payload;
      console.log("global slice called ", state.successfulUploads, state.totalUploads)
    },
    setFailedUploads: (state, action) => {
      state.failedUploads = action.payload;
      console.log("FAILED STATUS UPDATED IN STORE", state.failedUploads)
    },
    addFailedUploads: (state,action)=>{
      state.failedUploads = [...state.failedUploads,action.payload]
    },
    setTotalFiles: (state, action) => {
      state.totalFiles = action.payload;
    },
    clearFailedUploads:(state,action)=>{
      state.failedUploads = [];
    },
    clearUploadStatus: (state,action) => {
      state.setUploadStatus = [];
    },



    //BOMA CLUSTER
    setShowBomaCluster: (state, action) => {
      state.showBomaCluster = action.payload;
    },
    setBomaClusterDetails: (state, action) => {
      state.bomaClusterDetails = action.payload;
    },








    //REVIEW
    setIsReviewing: (state, action) => {
      state.isReviewing = action.payload;
    },
    setBomaReviewDetails: (state,action)=>{
      state.bomaReviewDetails = action.payload;

    },
    setImageReviewDetails: (state,action) => {
      state.imageReviewDetails = action.payload;
    },


    setBomaAnalysisDetails: (state,action)=>{
      state.bomaAnalysisDetails = action.payload;
    },





    setImagesNameToId : (state, action) => {
      state.imagesNameToId = action.payload;
    },

    setHighlightedBomaDetails: (state,action) => {
      state.highlightedBomaDetails = action.payload;
    },
    setHighlightedImageDetails: (state,action) => {
      state.highlightedImageDetails = action.payload;
    },



    setBomaCattleCount: (state,action) => {
      state.bomaCattleCount = action.payload;
    },

    setBomaPopupDetails : (state,action) => {
      state.bomaPopupDetails = action.payload;
    },
    setActivePopup: (state,action) => {
      state.activePopup = action.payload;
    },







    setShowDeleteBomaModal: (state, action) => {
      state.showDeleteBomaModal = action.payload;
    },
    setBomaIdToBeDeleted: (state, action) => {
      state.bomaIdToBeDeleted = action.payload;
    },



    //state to reload the application
    setBomaCreated: (state,action) => {
      state.bomaCreated = state.bomaCreated +1;



      //reset other states of the files in store

      state.isUploading= false;
      state.totalUploads= 0;
      state.showUploadModal = false;
    
    
      // //files
      state.selectedFiles= [];
      state.uploadStatus= [];
      state.failedUploads= [];
      state.successfulUploads= 0;
      state.totalFiles= 0;



    },
    setBomaDeleted: (state,action) => {
      state.bomaDeleted = state.bomaDeleted + 1;
    },



    setJobStatusList : (state,action) => {
      state.jobStatusList = action.payload;
    },



    setAnalysisJobList : (state, action) => {
      if(typeof action.payload == "number")
      state.analysisJobList.push(action.payload);
      else{
        state.analysisJobList = action.payload
      }
    },

    setUploadSuccessful : (state, action) => {
      state.uploadSuccessful = action.payload;
    }



  },





  extraReducers: (builder) => {
    builder.addCase('RESET_STATE', () => initialState); // Reset state
  },
});

export const {
  setUserBomaData,

  setFilteredData,
  setIsUploading,
  setTotalUploads,
  setIsAnalysing,
  setShowUploadModal,
  setCollectionId,
  setIsSidebarOpen,

  //Files
  setSelectedFiles,
  setUploadStatus,
  setSuccessfulUploads,
  setFailedUploads,
  setTotalFiles,
  clearFailedUploads,
  addFailedUploads,

  //CLUSTER
  setShowBomaCluster,
  setBomaClusterDetails,

  //REVIEW
  setIsReviewing,
  setBomaReviewDetails,
  setImageReviewDetails,
  setBomaAnalysisDetails,



  setImagesNameToId,
  setHighlightedBomaDetails,
  setHighlightedImageDetails,



  setBomaCattleCount,
  setBomaPopupDetails,
  setActivePopup,


  
  setShowDeleteBomaModal,
  setBomaIdToBeDeleted,


  setBomaCreated,
  setBomaDeleted,


  setJobStatusList,
  setAnalysisJobList,

  setUploadSuccessful,
  clearUploadStatus,
} = globalSlice.actions;
export default globalSlice.reducer;
