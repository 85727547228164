function extractDate(dateString) {
  // Use the Date constructor to parse the string
  const parsedDate = new Date(dateString);

  if (isNaN(parsedDate)) {
      throw new Error("Invalid date string");
  }

  // Format the date in a readable format (YYYY-MM-DD)
  const year = parsedDate.getUTCFullYear();
  const month = String(parsedDate.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(parsedDate.getUTCDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function groupGeoJSONByDate(geoJSONData) {
  // Step 1: Group features by the date in properties
  const groupedFeatures = geoJSONData.features.reduce((acc, feature) => {
    // const date = extractDate(feature.dateCreated); // Extract the date from
    const date = extractDate(feature.properties.timeCreated);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(feature);
    return acc;
  }, {});

  // Step 2: Convert grouped features into separate FeatureCollection objects
  const geoJSONByDate = Object.entries(groupedFeatures).map(([date, features]) => ({
    type: "FeatureCollection",
    crs: geoJSONData.crs,  // Keep the CRS (Coordinate Reference System) consistent across each collection
    date: date, // Optional: Add a date field for easier identification
    features: features,
  }));

  return geoJSONByDate;
}

export function parseJSONWithCustomDates(jsonString) {
  const data = JSON.parse(jsonString);

  // Recursive function to walk through the JSON object and convert date strings
  function traverseAndConvertDates(obj) {
    for (const key in obj) {
      if (
        typeof obj[key] === "string" &&
        /^\d{4}:\d{2}:\d{2} \d{2}:\d{2}:\d{2}$/.test(obj[key])
      ) {
        obj[key] = extractDate(obj[key]);
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        traverseAndConvertDates(obj[key]);
      }
    }
  }

  traverseAndConvertDates(data);
  return data;
}