import mapboxgl from 'mapbox-gl';

export const createPopup = ({
  mapRef,
  coordinates,
  featureDetails,
  isLoggedIn,
  // activePopup,
  // setActivePopup,
  // dispatch,
  onReviewClick,
}) => {


  const { latitude, longitude } = coordinates;
  const { id, region, datetime } = featureDetails;

  if (!latitude || !longitude) return;

  const popupAvailable = document.getElementsByClassName('mapboxgl-popup');
  if ( popupAvailable.length ) {
    popupAvailable[0].remove();
  }




  const container = document.createElement("div");
  container.className = "popup-container";
  container.style.position = "relative";

  const bomaId = document.createElement("p");
  bomaId.innerHTML = `<strong>Boma Id : </strong> ${id}`;

  const bomaLocation = document.createElement("p");
  bomaLocation.innerHTML = `<strong>Region : </strong> ${region}`;

  const bomaDateTime = document.createElement("p");
  bomaDateTime.innerHTML = `<strong>Date-Time : </strong> ${datetime}`;

  container.appendChild(bomaId);
  container.appendChild(bomaLocation);
  container.appendChild(bomaDateTime);

  if (isLoggedIn) {
    const reviewButton = document.createElement("button");
    reviewButton.textContent = "Review";
    reviewButton.className = "review-button";
    reviewButton.style.cssText =
      "padding: 5px 10px; cursor: pointer; margin-right: 5px; background-color: #4A90E2; color: white; border: none; border-radius: 4px;";

    reviewButton.addEventListener("click", (e) => {
      e.stopPropagation();
      // if (activePopup) activePopup.remove(); // Close the popup when the button is clicked
      if (onReviewClick) onReviewClick(featureDetails);
    });

    container.appendChild(reviewButton);
  }

  // Create a new popup
  const popup = new mapboxgl.Popup({ offset: 25, closeButton: false })
    .setLngLat([longitude, latitude])
    .setDOMContent(container)
    .addTo(mapRef);


};
