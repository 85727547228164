import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { logout } from "../store/slices/authSlice.js";
import userIcon from "../assets/icons/user.png";
import smallUserIcon from "../assets/icons/user-icon-small.png";
import dropIcon from "../assets/icons/dropdownTriangle.png";
import { toast } from "react-toastify";
import { setShowUploadModal, setBomaCreated, setTotalFiles, setIsUploading, setSuccessfulUploads,
  setSelectedFiles, setUploadStatus, addFailedUploads, clearFailedUploads,
  setFailedUploads,clearUploadStatus

,setUploadSuccessful

 } from "../store/slices/globalSlice.js";

import authService from "../utils/auth.js";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authStatus = useSelector((state) => state.auth.isLoggedIn);


  const successfulUploads = useSelector(
    (state) => state.global.successfulUploads
  );
  const totalFiles = useSelector((state) => state.global.totalFiles);

  const [isLoggedIn, setIsLoggedIn] = useState(authStatus);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadTime, setUploadTime] = useState(0);
  // const [isAnalysing, setIsAnalysing] = useState(false);
  // const [uploadSuccessful, setUploadSuccessful] = useState(false);

  const uploadSuccessful = useSelector((state) => state.global.uploadSuccessful);



  const isUploading = useSelector((state) => state.global.isUploading);
  const isAnalysing = useSelector((state) => state.global.isAnalysing);

  const [isLoading, setIsLoading] = useState(false);

  const username = sessionStorage.getItem("username");
  
  console.log("USER NAME", username);


  const analysisJobList = useSelector((state) => state.global.analysisJobList); //list of jobs
  const totalJobs = analysisJobList.length;


  useEffect(() => {
    setIsLoggedIn(authStatus);
  }, [authStatus]); // Sync isLoggedIn state with authStatus

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleLogin = () => {
    navigate("/login");
  };

  const handleRegister = () => {
    navigate("/signup");
  };

  const handleLogout = async () => {
    try {
      setIsLoading(true);
      const userData = await authService.logout();
      setIsLoading(false);

      if (userData) {
        dispatch(logout());
        setIsLoggedIn(false);
        setIsMenuOpen(false);

        dispatch({ type: "RESET_STATE" }); //reset all the global state to initial state
        toast.success("Logout Successful");
        navigate("/");
      }
    } catch (error) {
      // setError(error.message);
      setIsLoading(false);
      // console.log(error);
      toast.error("Logout Failed");
    }
  };

  const uploadImages = () => {
    dispatch(setShowUploadModal(true)); //show the upload image modal
    setUploadProgress(0);
    setUploadTime(0);
  };

  const showUploadModal = () => {
    dispatch(setShowUploadModal(true));
  };

  if (
    successfulUploads === totalFiles &&
    totalFiles !== 0 &&
    uploadSuccessful === false
  ) {
    dispatch(setShowUploadModal(true));
    dispatch(setUploadSuccessful(true));
    // dispatch(setBomaCreated());

    //clear files from store

    // dispatch(setSelectedFiles([]));
    // dispatch(clearUploadStatus());
    // dispatch(setSuccessfulUploads(0));
    // dispatch(setFailedUploads([]));
    // dispatch(setTotalFiles(0));

    // dispatch(setIsUploading(false));
  }



  return (
    <nav
      className="absolute top-2 right-2 z-10 p-3 rounded-lg  text-white"
      style={{ background: "#1E1E20" }}
    >
      {!isLoggedIn ? (
        <div className="flex space-x-2">
          <button
            onClick={handleLogin}
            className="px-10 py-1 text-white rounded-xl bg-blue-600 hover:bg-blue-700"
          >
            Log in
          </button>
          <button
            className="px-10 py-1 border-2 rounded-xl hover:bg-blue-100 transition duration-300 text-blue-400 border-blue-400"
            onClick={handleRegister}
          >
            Register
          </button>
        </div>
      ) : (
        <div className="flex items-center space-x-4">
          {isUploading && (
            <div className="flex">
              <div className="px-2 py-1" onClick={showUploadModal}>
                <div className="flex items-center space-x-2">
                  {!uploadSuccessful && (
                    <svg
                      className="animate-spin h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  <span className="text-sm">Uploading images</span>
                  <span className="text-sm font-bold">
                    {/* {uploadProgress}/100 */}
                    {successfulUploads}/{totalFiles}
                  </span>
                </div>
                <div className="w-full bg-gray-700 rounded-full h-1 mt-3">
                  <div
                    className="bg-yellow-500 h-1 rounded-full"
                    style={{
                      width: `${(successfulUploads / totalFiles) * 100}%`,
                    }}
                  ></div>
                </div>
              </div>

              {/* <button
                onClick={() => dispatch(setIsUploading(false))}
                className="text-gray-400 hover:text-white"
              >
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button> */}
            </div>
          )}

          {/* New isAnalysing Div */}
          { (totalJobs > 0) && (
            <div className="flex">
              <div
                className="px-2 py-2"

                // onClick={showUploadModal}
              >
                <div className="flex items-center space-x-2">
                  <svg
                    className="animate-spin h-5 w-5 text-green-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>

                  <span className="text-sm font-bold">
                    {/* {analysisProgress}/100 */}
                    {totalJobs} 
                  </span>
                  <span className="text-sm">
                    Analysis running 
                    {/* (Id {collectionId}) */}
                    {/* ({formatTime(analysisTime)}) */}
                  </span>

                  {/* <span className="text-sm">
                    {/* {analysisProgress}/100 */}
                    {/* 40%
                  </span> */} 
                </div>
                <div className="w-full bg-gray-700 rounded-full h-1 mt-3">
                  <div
                    className="bg-green-500 h-1 rounded-full"
                    style={{
                      // width: `${analysisProgress}%`,
                      width: `${100}%`,
                    }}
                  ></div>
                </div>
              </div>

              {/* <button
                onClick={() => dispatch(setIsAnalysing(false))}
                className="text-gray-400 hover:text-white"
              >
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button> */}
            </div>
          )}

          <button
            onClick={uploadImages}
            className={`px-4 py-2 text-white rounded-xl transition duration-300 ${
              isUploading
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-600 hover:bg-blue-700"
            }`}
            disabled={isUploading} // Disable the button interaction when isUploading is true
          >
            Add new images
          </button>

          <img
            src={userIcon}
            alt="User"
            className="w-10 h-10 rounded-full cursor-pointer"
            onClick={toggleMenu}
          />
          <button onClick={toggleMenu}>
            <img alt="dropdown icon" src={dropIcon} className="w-3 h-3" />
          </button>
        </div>
      )}

      {/* drop down menu */}
      {isMenuOpen && (
        <div
          className="absolute right-0 mt-2 min-w-44 rounded-md shadow-lg bg-gray-800 ring-1 ring-black ring-opacity-5"
          style={{ background: "#1E1E20" }}
        >
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <div className="flex items-center px-4 py-2">
              <img
                src={smallUserIcon}
                alt="small user icon"
                className="h-5 mr-3"
              />
              {username}
            </div>

            <button
              onClick={handleLogout}
              className="flex items-center px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white w-full text-left"
              role="menuitem"
            >
              <svg
                className="mr-3 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
              {/* Log out */}
              {isLoading ? "Logging out..." : "Log out"}
            </button>
            <div className="px-4 py-2 text-sm text-gray-300">
              <span className="flex items-center">
                <svg
                  className="mr-3 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                Need help?
              </span>
              <a
                href="www.youtube.com"
                className="block mt-2 text-blue-400 hover:underline"
              >
                Watch a video
              </a>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
