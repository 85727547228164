//convert the incoming data of the boma to the geojson format required

export function convertDataToGeoJSON(inputData) {
    const featureCollection = {
        type: "FeatureCollection",
        crs: {
            type: "name",
            properties: {
                name: "urn:ogc:def:crs:OGC:1.3:CRS84"
            }
        },
        features: []
    };

    inputData.forEach(boma => {
        // Main Boma feature
        const mainFeature = {
            type: "Feature",
            properties: {
                id: boma.id,
                name: boma.name,
                datetime: boma.features[0].properties.datetime,
                make: boma.features[0].properties.make,
                model: boma.features[0].properties.model,
                region: boma.features[0].properties.region,
                images: boma.features[0].properties.images,
                imagesGeojson: {
                    type: "FeatureCollection",
                    features: []
                },
                timeCreated: boma.time_created

            },
            geometry: {
                type: "Point",
                coordinates: boma.features[0].geometry.coordinates
            },
        };

        // Add each image with its specific coordinates to imagesGeojson
        boma.features[0].properties.images.forEach((imageName, index) => {
            const imageCoordinates = boma.features[0].properties.coordinates[0][index];

            const imageFeature = {
                type: "Feature",
                properties: {
                    imageName: imageName
                },
                geometry: {
                    type: "Point",
                    coordinates: [imageCoordinates[0], imageCoordinates[1], 0]
                }
            };

            mainFeature.properties.imagesGeojson.features.push(imageFeature);
        });

        // Add the main feature to the collection
        featureCollection.features.push(mainFeature);
    });

    return featureCollection;
}





// // Convert data to GeoJSON
// const geoJSONOutput = convertDataToGeoJSON(inputData);
// console.log(JSON.stringify(geoJSONOutput, null, 2));

// module.exports = {convertDataToGeoJSON}
