import React, { useRef, useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

import MapLayer from "./layers/MapLayer.jsx";
import ReviewLayer from "./layers/ReviewLayer.jsx";
import BaseLayer from "./layers/BaseLayer.jsx";

import {
  setHighlightedBomaDetails,
  setHighlightedImageDetails,
  setIsReviewing,
  setShowBomaCluster,
} from "../../store/slices/globalSlice.js";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const MapComponent = () => {
  const filteredData = useSelector((state) => state.global.filteredData);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  const [mapState, setMapState] = useState({
    lng: 37.9062,
    lat: 0.0236,
    zoom: 2,
  });

  //TODO: automatically find the current location of the user based on IP


  const [mapLoaded, setMapLoaded] = useState(false);

  const initializeMap = useCallback(() => {
    try {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: process.env.REACT_APP_MAP_STYLE,
        center: [mapState.lng, mapState.lat],
        zoom: mapState.zoom,
      });

      mapRef.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");
      mapRef.current.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: { enableHighAccuracy: true },
          trackUserLocation: true,
          showUserHeading: true,
        }),
        "bottom-right"
      );

      mapRef.current.on("load", () => {
        if (mapRef.current.isStyleLoaded()) {
          setMapLoaded(true);
        }
      });


    } catch (error) {
      console.error("Failed to initialize the map:", error);
    }
  }, [mapState]);

  useEffect(() => {
    initializeMap();

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
      setMapLoaded(false);
    };
  }, [initializeMap]);

  useEffect(() => {
    if (mapRef.current && mapRef.current.getSource("earthquakes")) {
      try {
        mapRef.current.getSource("earthquakes").setData(filteredData);
      } catch (error) {
        console.error("Error updating data source:", error);
      }
    }
  }, [filteredData]);

  useEffect(() => {
    dispatch(setShowBomaCluster(false));
    dispatch(setIsReviewing(false));
    dispatch(setHighlightedBomaDetails(null));
    dispatch(setHighlightedImageDetails(null));
  }, [isLoggedIn, dispatch]);

  return (
    <div className="relative">
      <div ref={mapContainerRef} className="map-container w-full h-screen" />
      {!mapLoaded && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 text-white">
          Loading map...
        </div>
      )}
      {mapLoaded && (
        <>
          <BaseLayer mapRef={mapRef} />
          <MapLayer mapRef={mapRef} />
          <ReviewLayer mapRef={mapRef} />
        </>
      )}
    </div>
  );
};

export default MapComponent;
