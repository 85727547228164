// import { useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import {
//   setBomaReviewDetails,
//   setIsReviewing,
//   setIsSidebarOpen,
//   setShowBomaCluster,
//   setBomaPopupDetails,
//   setBomaClusterDetails,
//   setActivePopup
// } from "../../../store/slices/globalSlice.js";

// import { createPopup } from "../../../utils/createPopup.js";

// const BaseLayer = ({ mapRef }) => {
//   const dispatch = useDispatch();

//   const showBomaCluster = useSelector((state) => state.global.showBomaCluster);
//   const isReviewing = useSelector((state) => state.global.isReviewing);
//   const filteredData = useSelector((state) => state.global.filteredData);
//   const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
//   const bomaPopupDetails = useSelector((state) => state.global.bomaPopupDetails);
//   const activePopup = useSelector((state) => state.global.activePopup)

//   useEffect(() => {
//     if (!mapRef) return;

//     if (!mapRef.current) return;

//     if (!mapRef.current.isStyleLoaded()) return;

//     // Check if conditions are met to avoid adding the base layer
//     if (isReviewing || showBomaCluster) {

//       // Remove existing layers if they are present
//       if (mapRef.current && mapRef.current.isStyleLoaded()) {
//         if (mapRef.current.getLayer("clusters")) {
//           mapRef.current.removeLayer("clusters");
//           mapRef.current.removeLayer("cluster-count");
//           mapRef.current.removeLayer("unclustered-point");
//         }
//         if (mapRef.current.getSource("earthquakes")) {
//           mapRef.current.removeSource("earthquakes");
//         }
//       } else {
//         console.log("Map instance not ready or unloaded; skipping cleanup.");
//       }

//       return;
//     }

//     if (mapRef.current && mapRef.current.isStyleLoaded()) {
//       if (mapRef.current.getLayer("clusters")) {
//         mapRef.current.removeLayer("clusters");
//         mapRef.current.removeLayer("cluster-count");
//         mapRef.current.removeLayer("unclustered-point");
//       }
//       if (mapRef.current.getSource("earthquakes")) {
//         mapRef.current.removeSource("earthquakes");
//       }
//     } else {
//       console.log("Map instance not ready or unloaded; skipping cleanup.");
//     }
//     mapRef.current.addSource("earthquakes", {
//       type: "geojson",
//       data: filteredData,
//       cluster: true,
//       clusterMaxZoom: 14,
//       clusterRadius: 50,
//     });

//     mapRef.current.addLayer({
//       id: "clusters",
//       type: "circle",
//       source: "earthquakes",
//       filter: ["has", "point_count"],
//       paint: {
//         "circle-color": [
//           "step",
//           ["get", "point_count"],
//           "#51bbd6",
//           100,
//           "#f1f075",
//           750,
//           "#f28cb1",
//         ],
//         "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
//       },
//     });

//     mapRef.current.addLayer({
//       id: "cluster-count",
//       type: "symbol",
//       source: "earthquakes",
//       filter: ["has", "point_count"],
//       layout: {
//         "text-field": ["get", "point_count_abbreviated"],
//         "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
//         "text-size": 12,
//       },
//     });

//     mapRef.current.addLayer({
//       id: "unclustered-point",
//       type: "circle",
//       source: "earthquakes",
//       filter: ["!", ["has", "point_count"]],
//       paint: {
//         "circle-color": "#11b4da",
//         "circle-radius": 8,
//         "circle-stroke-width": 1,
//         "circle-stroke-color": "#fff",
//       },
//     });

//     // Define interactions and popups for the unclustered points
//     mapRef.current.on("click", "clusters", (e) => {
//       const features = mapRef.current.queryRenderedFeatures(e.point, {
//         layers: ["clusters"],
//       });
//       const clusterId = features[0].properties.cluster_id;
//       mapRef.current
//         .getSource("earthquakes")
//         .getClusterExpansionZoom(clusterId, (err, zoom) => {
//           if (err) return;
//           mapRef.current.easeTo({
//             center: features[0].geometry.coordinates,
//             zoom: zoom,
//           });
//         });
//     });

//     if(bomaPopupDetails){
//       console.log("BASE LAYER BOMA POPUP", bomaPopupDetails);

//       const featureDetails = {
//         id : bomaPopupDetails.properties?.id,
//         region : bomaPopupDetails.properties?.region,
//         datetime : bomaPopupDetails.properties?.datetime
//        }
  
//       const coordinates = {
//         latitude: bomaPopupDetails.geometry?.coordinates[1],
//         longitude: bomaPopupDetails.geometry?.coordinates[0]
//       }


//       createPopup({
//         mapRef: mapRef.current,
//         coordinates,
//         featureDetails,
//         isLoggedIn,

//         onReviewClick: (details) => {
//           dispatch(setBomaReviewDetails(JSON.stringify(bomaPopupDetails)));
//           dispatch(setIsReviewing(true));
//           dispatch(setIsSidebarOpen(true));
//           dispatch(setShowBomaCluster(false));
//       // dispatch(setBomaPopupDetails(null))

//           //clear all the popups
//           const popupAvailable = document.getElementsByClassName('mapboxgl-popup');
//           // console.log("AVAILABLE POPUP", popupAvailable)
//           if ( popupAvailable.length ) {
//             popupAvailable[0].remove();
//           }

//         },
//       });
//     }

//     mapRef.current.on("click", "unclustered-point", (entryPoint) => {
//       const bomaClusterInputData = {
//           geometry : entryPoint.features[0].geometry,
//           properties : entryPoint.features[0].properties,

//       };

      

//       dispatch(setBomaPopupDetails(bomaClusterInputData))
//       dispatch(setBomaClusterDetails(JSON.stringify(entryPoint.features[0])));
//       dispatch(setBomaReviewDetails(JSON.stringify(entryPoint.features[0])));
//     });

//     return () => {
//       if (mapRef.current && mapRef.current.isStyleLoaded()) {
//         if (mapRef.current.getLayer("clusters")) {
//           mapRef.current.removeLayer("clusters");
//           mapRef.current.removeLayer("cluster-count");
//           mapRef.current.removeLayer("unclustered-point");
//         }
//         if (mapRef.current.getSource("earthquakes")) {
//           mapRef.current.removeSource("earthquakes");
//         }
//       } else {
//         console.log("Map instance not ready or unloaded; skipping cleanup.");
//       }
//     };
//   }, [
//     filteredData,
//     showBomaCluster,
//     isReviewing,
//     isLoggedIn,
//     dispatch,
//     mapRef,
//     bomaPopupDetails
//   ]);

//   return null;
// };

// export default BaseLayer;










import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setBomaReviewDetails,
  setIsReviewing,
  setIsSidebarOpen,
  setShowBomaCluster,
  setBomaPopupDetails,
  setBomaClusterDetails,
} from "../../../store/slices/globalSlice.js";
import { createPopup } from "../../../utils/createPopup.js";

const BaseLayer = ({ mapRef }) => {
  const dispatch = useDispatch();

  // Redux selectors
  const showBomaCluster = useSelector((state) => state.global.showBomaCluster);
  const isReviewing = useSelector((state) => state.global.isReviewing);
  const filteredData = useSelector((state) => state.global.filteredData);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const bomaPopupDetails = useSelector((state) => state.global.bomaPopupDetails);

  // Function to clear layers and sources
  const clearLayersAndSources = () => {
    if (mapRef.current && mapRef.current.isStyleLoaded()) {
      ["clusters", "cluster-count", "unclustered-point"].forEach((layer) => {
        if (mapRef.current.getLayer(layer)) mapRef.current.removeLayer(layer);
      });
      if (mapRef.current.getSource("earthquakes")) {
        mapRef.current.removeSource("earthquakes");
      }
    } else {
      console.log("Map instance not ready or unloaded; skipping cleanup.");
    }
  };

  // Function to add source and layers for the base layer
  const addBaseLayer = () => {
    if (!mapRef.current || !filteredData) return;

    mapRef.current.addSource("earthquakes", {
      type: "geojson",
      data: filteredData,
      cluster: true,
      clusterMaxZoom: 14,
      clusterRadius: 50,
    });

    mapRef.current.addLayer({
      id: "clusters",
      type: "circle",
      source: "earthquakes",
      filter: ["has", "point_count"],
      paint: {
        "circle-color": [
          "step",
          ["get", "point_count"],
          "#51bbd6",
          100,
          "#f1f075",
          750,
          "#f28cb1",
        ],
        "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
      },
    });

    mapRef.current.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: "earthquakes",
      filter: ["has", "point_count"],
      layout: {
        "text-field": ["get", "point_count_abbreviated"],
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 12,
      },
    });

    mapRef.current.addLayer({
      id: "unclustered-point",
      type: "circle",
      source: "earthquakes",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-color": "#11b4da",
        "circle-radius": 8,
        "circle-stroke-width": 1,
        "circle-stroke-color": "#fff",
      },
    });
  };

  // Add popup interaction for `bomaPopupDetails`
  const handleBomaPopup = () => {
    if (bomaPopupDetails) {
      console.log(bomaPopupDetails)
      const featureDetails = {
        id: bomaPopupDetails.properties?.id,
        region: bomaPopupDetails.properties?.region,
        datetime: bomaPopupDetails.properties?.timeCreated,
      };

      const coordinates = {
        latitude: bomaPopupDetails.geometry?.coordinates[1],
        longitude: bomaPopupDetails.geometry?.coordinates[0],
      };

      createPopup({
        mapRef: mapRef.current,
        coordinates,
        featureDetails,
        isLoggedIn,
        onReviewClick: () => {
          dispatch(setBomaReviewDetails(JSON.stringify(bomaPopupDetails)));
          dispatch(setIsReviewing(true));
          dispatch(setIsSidebarOpen(true));
          dispatch(setShowBomaCluster(false));

          // Remove any existing popups
          const popups = document.getElementsByClassName("mapboxgl-popup");
          if (popups.length) popups[0].remove();
        },
      });
    }
  };

  // Handle cluster interactions
  const handleClusterInteractions = () => {
    mapRef.current.on("click", "clusters", (e) => {
      const features = mapRef.current.queryRenderedFeatures(e.point, {
        layers: ["clusters"],
      });
      const clusterId = features[0].properties.cluster_id;

      mapRef.current.getSource("earthquakes").getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return;
        mapRef.current.easeTo({
          center: features[0].geometry.coordinates,
          zoom: zoom,
        });
      });
    });

    mapRef.current.on("click", "unclustered-point", (entryPoint) => {
      const featureData = entryPoint.features[0];
      console.log("FEATURES DATA", featureData)
      const bomaClusterInputData = {
        geometry: featureData.geometry,
        properties: featureData.properties,
      };

      dispatch(setBomaPopupDetails(bomaClusterInputData));
      dispatch(setBomaClusterDetails(JSON.stringify(featureData)));
      dispatch(setBomaReviewDetails(JSON.stringify(featureData)));
    });
  };

  useEffect(() => {
    if (!mapRef?.current || !mapRef.current.isStyleLoaded()) return;

    if (isReviewing || showBomaCluster) {
      clearLayersAndSources();
      return;
    }

    clearLayersAndSources();
    addBaseLayer();
    handleClusterInteractions();
    handleBomaPopup();

    return () => clearLayersAndSources();
  }, [
    filteredData,
    showBomaCluster,
    isReviewing,
    isLoggedIn,
    dispatch,
    mapRef,
    bomaPopupDetails,
  ]);

  return null; // This component manages map layers and interactions
};

export default BaseLayer;
