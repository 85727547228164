import React from "react";

function LoadingScreen() {
  return (
    <div className="flex items-center justify-center h-screen text-white"
    style={{ background: "#1E1E20" }} 
    
    >
      <div className="flex flex-col items-center space-y-4">
        {/* Rotating Circle */}
        <div className="w-16 h-16 border-4 border-t-4 border-t-blue-500 border-gray-200 rounded-full animate-spin"></div>
        {/* Loading Message */}
        <p className="text-lg font-semibold">We are getting things ready for you...</p>
      </div>
    </div>
  );
}

export default LoadingScreen;
