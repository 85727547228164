import { useEffect } from "react";
import {
  calculateBounds,
  calculateCenter,
  createCircle,
  extractLatLong,
} from "../../../utils/mapUtils.js";

import { useSelector, useDispatch } from "react-redux";
import {
  setIsReviewing,
  setIsSidebarOpen,
  setImageReviewDetails,
  setHighlightedImageDetails,
  setHighlightedBomaDetails,
  setBomaPopupDetails,
} from "../../../store/slices/globalSlice.js";

const ReviewLayer = ({ mapRef }) => {
  const isReviewing = useSelector((state) => state.global.isReviewing);
  let reviewLayerData = useSelector((state) => state.global.bomaReviewDetails);

  const highlightedImageDetails = useSelector(
    (state) => state.global.highlightedImageDetails
  );

  const dispatch = useDispatch();

  if (typeof reviewLayerData == "string") {
    reviewLayerData = JSON.parse(reviewLayerData);
    reviewLayerData = reviewLayerData.properties.imagesGeojson;

    if (typeof reviewLayerData == "string")
      reviewLayerData = JSON.parse(reviewLayerData);
  }

  let circleCoordinates = null;

  try {
    circleCoordinates = extractLatLong(reviewLayerData);
  } catch (error) {
    // console.log("ERROR IN EXTRACTING CIRCLE COORDINATES", error);
  }

  if (mapRef.current && mapRef.current.isStyleLoaded()) {
    // console.log("Removing layers and sources");

    if (mapRef.current.getLayer("circle-layer")) {
      // console.log("Removing circle-layer");
      mapRef.current.removeLayer("circle-layer");
    }

    if (mapRef.current.getSource("circle")) {
      // console.log("Removing circle source");
      mapRef.current.removeSource("circle");
    }

    if (mapRef.current.getLayer("cluster-points-layer")) {
      // console.log("Removing cluster-points-layer");
      mapRef.current.removeLayer("cluster-points-layer");
    }

    if (mapRef.current.getSource("geojson-points")) {
      // console.log("Removing geojson-points source");
      mapRef.current.removeSource("geojson-points");
    }
  } else {
    // console.log("Map instance not ready or unloaded; skipping cleanup.");
  }

  useEffect(() => {
    if (isReviewing && mapRef.current && reviewLayerData) {
      if (circleCoordinates && circleCoordinates.length > 0) {
        const center = calculateCenter(circleCoordinates);
        const circleCoords = createCircle(center, 0.4);

        if (mapRef.current.getLayer("review-layer-circle")) {
          mapRef.current.removeLayer("review-layer-circle");
        }
        if (mapRef.current.getSource("circle-review-boundary")) {
          mapRef.current.removeSource("circle-review-boundary");
        }

        mapRef.current.addSource("circle-review-boundary", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: { type: "Polygon", coordinates: [circleCoords] },
          },
        });

        mapRef.current.addLayer({
          id: "review-layer-circle",
          type: "fill",
          source: "circle-review-boundary",
          layout: {},
          paint: {
            "fill-color": "#585FFF",
            "fill-opacity": 0.2,
            "fill-outline-color": "#ffffff",
          },
        });

        reviewLayerData &&
          addPointsLayer(reviewLayerData, highlightedImageDetails);

        const bounds = calculateBounds(circleCoords);
        mapRef.current.fitBounds(bounds, { padding: 40 });

        mapRef.current.on("click", "review-layer", (e) => {
          console.log("POINT DATA", e.features[0]);

          const features = mapRef.current.queryRenderedFeatures(e.point, {
            layers: ["review-layer"],
          });
          console.log("review layer line 99");

          if (features.length > 0) {
            dispatch(setIsReviewing(true));
            dispatch(setIsSidebarOpen(true));
            const imgDetails = {
              properties: features[0].properties,
              geometry: features[0].geometry,
            };

            dispatch(setImageReviewDetails(imgDetails));

            dispatch(setHighlightedImageDetails(features[0].geometry));
            dispatch(setHighlightedBomaDetails(null));
          }
        });
      }

      return () => {
        console.log("CLEANUP CALLED IN REVIEW LAYER");

        dispatch(setBomaPopupDetails(null));

        if (mapRef.current && mapRef.current.isStyleLoaded()) {
          if (mapRef.current.getLayer("review-layer-circle")) {
            mapRef.current.removeLayer("review-layer-circle");
          }
          if (mapRef.current.getSource("circle-review-boundary")) {
            mapRef.current.removeSource("circle-review-boundary");
          }
          if (mapRef.current.getLayer("review-layer")) {
            mapRef.current.removeLayer("review-layer");
          }
          if (mapRef.current.getSource("review-points")) {
            mapRef.current.removeSource("review-points");
          }
        } else {
          console.log("Map instance not ready or unloaded; skipping cleanup.");
        }
      };
    }
  }, [isReviewing, reviewLayerData]);

  const addPointsLayer = (data, highlightDetails) => {

    try {
      if (mapRef.current) {
        if (mapRef.current.getSource("review-points"))
          mapRef.current.removeSource("review-points");

        mapRef.current.addSource("review-points", {
          type: "geojson",
          data: data,
        });

        if (mapRef.current.getLayer("review-layer"))
          mapRef.current.removeLayer("review-layer");

        // if (!highlightDetails) {
        //   mapRef.current.addLayer({
        //     id: "review-layer",
        //     type: "circle",
        //     source: "review-points",
        //     paint: {
        //       "circle-radius": 7,
        //       "circle-color": "#FFFF00",
        //       "circle-opacity": 1,
        //     },
        //   });
        // } else {

        // Create a new data object with updated features
        const updatedData = {
          ...data, // Copy the original data object
          features: data.features.map((feature) => {
            // For each feature, create a new object with updated properties
            return {
              ...feature,
              properties: {
                ...feature.properties,
                latitude: feature.geometry.coordinates[0], // Assign latitude from coordinates[0]
                longitude: feature.geometry.coordinates[1], // Assign longitude from coordinates[1]
              },
            };
          }),
        };


        if (!highlightDetails) {
          highlightDetails = {
            coordinates: [111, 222],
          };
        }
        if (mapRef.current.getSource("geojson-points")) {
          // console.log("Removing geojson-points source");
          mapRef.current.removeSource("geojson-points");
        }

        if (mapRef.current) {
          mapRef.current.addSource("geojson-points", {
            type: "geojson",
            data: updatedData,
          });

          mapRef.current.addLayer({
            id: "review-layer",
            type: "circle",
            source: "review-points",
            paint: {
              "circle-radius": [
                "case",
                [
                  "all",
                  ["==", ["get", "latitude"], highlightDetails?.coordinates[0]],
                  [
                    "==",
                    ["get", "longitude"],
                    highlightDetails?.coordinates[1],
                  ],
                ],
                10, // Larger radius for highlighted point
                7, // Default radius
              ],

              "circle-color": [
                "case",
                [
                  "all",
                  ["==", ["get", "latitude"], highlightDetails?.coordinates[0]],
                  [
                    "==",
                    ["get", "longitude"],
                    highlightDetails?.coordinates[1],
                  ],
                ],
                "#FF0000", // Highlighted color
                "#FFFF00", // Default color
              ],
              "circle-opacity": 1,
            },
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // };

  return null;
};

export default ReviewLayer;





