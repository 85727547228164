import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import WaitingScreen from "./pages/WaitingScreen.jsx";
import "react-toastify/dist/ReactToastify.css";
import { convertDataToGeoJSON } from "./utils/convertToGeojson.js";
import {
  setFilteredData,
  setUserBomaData,
  setBomaCattleCount,
  setBomaCreated,
  setBomaDeleted,
  setJobStatusList,
  setAnalysisJobList,
} from "./store/slices/globalSlice.js";
import { useDispatch, useSelector } from "react-redux";

import useOnlineStatus from "./hooks/useOnlineStatus.js";
import NoInternet from "./components/modals/NoInternet.jsx";
import AppRoutes from "./routes.js";

import DeleteBomaModel from "./components/modals/DeleteBomaModal.jsx";

function App() {
  // TODO: add maintenance page when the server is not working
  const dispatch = useDispatch();
  const isOnline = useOnlineStatus();

  const [loading, setLoading] = useState(true);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const showDeleteBomaModal = useSelector(
    (state) => state.global.showDeleteBomaModal
  );

  const BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;
  const accessToken = sessionStorage.getItem("accessToken");

  //reset state of boma created and boma deleted
  const bomaCreated = useSelector((state) => state.global.bomaCreated);
  const bomaDeleted = useSelector((state) => state.global.bomaDeleted);

  const jobStatusList = {};

  useEffect(() => {
    const fetchDataAndUpdate = async () => {
      if (!isOnline) return;
      // dispatch({ type: "RESET_STATE" }); //reset all the global state to initial state
      // dispatch(setUserBomaData([]));
      // dispatch(setFilteredData([]));


      try {
        let incomingGeoJSON = null;

        if (isLoggedIn) {
          console.log("FETCHING USER BOMA");
          const response = await fetch(`${BASE_URL}collections`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          });

          const data = await response.json();
          data.pop();
          console.log("LIST OF BOMA DATA", data);
          incomingGeoJSON = convertDataToGeoJSON(data);

          incomingGeoJSON.features.forEach(
            (item) => (item.properties.region = item.properties.region[0])
          );

          console.log("INCOMING GEOJSON DATA",incomingGeoJSON)
          incomingGeoJSON.features = incomingGeoJSON.features.filter(item => item.geometry.coordinates[0] != null)
          // incomingGeoJSON.pop();

          dispatch(setUserBomaData(incomingGeoJSON));
          dispatch(setFilteredData(incomingGeoJSON));
          setLoading(false);
        } else {
          console.log("FETCHING  BOMA");

          const response = await fetch(`${BASE_URL}collections/public`);
          const data = await response.json();
          data.pop();

          incomingGeoJSON = convertDataToGeoJSON(data);

          incomingGeoJSON.features.forEach(
            (item) => (item.properties.region = item.properties.region[0])
          );

          // incomingGeoJSON.pop();
          dispatch(setUserBomaData(incomingGeoJSON));
          dispatch(setFilteredData(incomingGeoJSON));
          setLoading(false);
        }

        if (!isLoggedIn) return;

        // Update cattle count
        console.log("updating cattle count");
        let bomaCattleCount = {};
        const fetchPromises = incomingGeoJSON.features.map(async (item) => {
          try {
            const response = await fetch(
              `${BASE_URL}analytics/results/collections/${item.properties.id}`,
              {
                method: "GET",
                headers: { Authorization: `Bearer ${accessToken}` },
              }
            );
            const data = await response.json();
            bomaCattleCount[item.properties.id] = data[0].result.count;
            jobStatusList[item.properties.id] = "completed";
          } catch (error) {
            console.error(
              `Failed to fetch data for ${item.properties.id}:`,
              error
            );
            // throw error("ERROR IN APP LINE 96", error)
          }
        });

        console.log("CATTLE COUNT LIST ", bomaCattleCount);

        await Promise.all(fetchPromises);
        dispatch(setBomaCattleCount(bomaCattleCount));
        // dispatch(setJobStatusList(jobStatusList));

        //call for in progress analysis jobs

        // const jobStatus = incomingGeoJSON.features.map(async (item) => {
        try {
          const response = await fetch(`${BASE_URL}analytics/collections`, {
            method: "GET",
            headers: { Authorization: `Bearer ${accessToken}` },
          });
          const data = await response.json();

          data.map((item) => {
            const bomaId = item.id;
            jobStatusList[bomaId] = "in-progress";
            dispatch(setAnalysisJobList(bomaId));
          });
        } catch (error) {
          console.error(`Failed to fetch job status:`, error);
          // throw error("ERROR IN APP LINE 96", error)
        }
        // });

        // await Promise.all(jobStatus);
        dispatch(setJobStatusList(jobStatusList));
      } catch (error) {
        console.log("ERROR ", error);
        // throw error("ERROR IN APP LINE 103", error)
      }
    };
    fetchDataAndUpdate();
  }, [
    BASE_URL,
    accessToken,
    dispatch,
    isLoggedIn,
    isOnline,
    bomaCreated,
    bomaDeleted,
    jobStatusList,
  ]);

  //remove all the popup if available
  useEffect(() => {
    const popupAvailable = document.getElementsByClassName("mapboxgl-popup");
    if (popupAvailable.length) {
      popupAvailable[0].remove();
    }
  }, [isLoggedIn]);

  if (!isOnline) {
    return <NoInternet show={true} />;
  }

  if (loading) {
    return <WaitingScreen />;
  }

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        style={{ height: "20px" }}
        limit={3}
      />

      {showDeleteBomaModal && <DeleteBomaModel />}

      <AppRoutes />
    </div>
  );
}

export default App;

/*
TODO:
1. while logged in, fetch the current ongoing analysis list from the server for that user
2. when boma are created, then the side menu should update and the server should send the list of new updated boma

3. review layer is not properly removed
4. while zooming in when we click on side menu and then click on another boma then it does not properly create popup

5. non serializable files in store while uploading them
6. when upload is done remove files form the store

7. update side menu when new boma is created or deleted

*/
